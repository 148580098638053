import useAppConfig from '@core/app-config/useAppConfig'

const initializeTreeviewStyles = () => {
  const { skin } = useAppConfig()
  if (skin.value === 'dark') {
    // eslint-disable-next-line no-unused-expressions
    import('@/libs/syncfusion/ej2-vue-navigations/styles/fabric-dark.css')
  } else {
    // eslint-disable-next-line no-unused-expressions
    import('@/libs/syncfusion/ej2-vue-navigations/styles/fabric.css')
  }
}
export default initializeTreeviewStyles
